import { DeleteTemplate, ITemplate, UpdateTemplate, WhatsappTemplateData, WhatsappTemplateSendData } from "../../models/template";
import {
  SORT_TEMPLATES,
  CLOSE_TEMPLATE_MODAL,
  CREATE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  INIT_CREATE_TEMPLATE,
  INIT_UPDATE_TEMPLATE,
  CREATE_UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESS,
  GET_TEMPLATES_CATEGORIES,
  GET_TEMPLATES_CATEGORIES_SUCCESS,
  UPDATE_TEMPLATES_CATEGORY,
  DELETE_TEMPLATES_CATEGORY,
  UPDATE_TEMPLATES_CATEGORY_SUCCESS,
  DELETE_TEMPLATES_CATEGORY_SUCCESS,
  GET_TAGLIST,
  GET_TAGLIST_SUCCESS,
  GET_WHATSAPP_TEMPLATE,
  GET_WHATSAPP_TEMPLATE_SUCCESS,
  SEND_WHATSAPP_TEMPLATE,
  SEND_WHATSAPP_TEMPLATE_SUCCESS,
  SHOW_WHATSAPP_TEMPLATE,
  SET_WHATSAPP_TEMPLATE_STATUS,
  TEMPLATE_MESSAGE_SEND
} from "../actions";

export const getTemplates = (culture: string) => ({
  type: GET_TEMPLATES,
  payload: culture
});

export const getTemplatesSuccess = (templates: []) => ({
  type: GET_TEMPLATES_SUCCESS,
  payload: templates,
});

export const getTagList = () => ({
  type: GET_TAGLIST,
});

export const getTagListSuccess = (tagList: any) => ({
  type: GET_TAGLIST_SUCCESS,
  payload: tagList,
});

export const createTemplateSuccess = (template: ITemplate) => ({
  type: CREATE_TEMPLATE_SUCCESS,
  payload: template,
});

export const initUpdateTemplate = (templateId: number) => ({
  type: INIT_UPDATE_TEMPLATE,
  payload: templateId,
});

export const initCreateTemplate = () => ({
  type: INIT_CREATE_TEMPLATE,
  payload: null,
});

export const createOrupdateTemplate = (template: ITemplate) => ({
  type: CREATE_UPDATE_TEMPLATE,
  payload: template,
});

export const updateTemplateSuccess = (template: ITemplate) => ({
  type: UPDATE_TEMPLATE_SUCCESS,
  payload: template,
});

export const deleteTemplate = (templateId: number) => ({
  type: DELETE_TEMPLATE,
  payload: templateId,
});

export const deleteTemplateSuccess = (templateId: number) => ({
  type: DELETE_TEMPLATE_SUCCESS,
  payload: templateId,
});

export const sortTemplates = (templates: []) => ({
  type: SORT_TEMPLATES,
  payload: templates,
});

export const closeTemplateModal = () => ({
  type: CLOSE_TEMPLATE_MODAL
});

export const getCategories = (culture: string) => ({
  type: GET_TEMPLATES_CATEGORIES,
  payload: culture
});

export const getCategoriesSuccess = (categories: []) => ({
  type: GET_TEMPLATES_CATEGORIES_SUCCESS,
  payload: categories,
});

export const updateTemplateCategory = (data: UpdateTemplate) => ({
  type: UPDATE_TEMPLATES_CATEGORY,
  payload: data,
});

export const deleteTemplateCategory = (data: DeleteTemplate) => ({
  type: DELETE_TEMPLATES_CATEGORY,
  payload: data,
});

export const updateTemplateCategorySuccess = (data: UpdateTemplate) => ({
  type: UPDATE_TEMPLATES_CATEGORY_SUCCESS,
  payload: data,
});

export const deleteTemplateCategorySuccess = (data: DeleteTemplate) => ({
  type: DELETE_TEMPLATES_CATEGORY_SUCCESS,
  payload: data,
});

export const getWhatsAppTemplate = () => ({
  type: GET_WHATSAPP_TEMPLATE,
});

export const showWhatsAppTemplate = (data: boolean) => ({
  type: SHOW_WHATSAPP_TEMPLATE,
  payload: data,
});

export const setWhatsAppTemplateStatus = (data: boolean) => ({
  type: SET_WHATSAPP_TEMPLATE_STATUS,
  payload: data,
});

export const getWhatsAppTemplateSuccess = (payload: WhatsappTemplateData) => ({
  type: GET_WHATSAPP_TEMPLATE_SUCCESS,
  payload,
});

export const sendWhatsAppTemplate = (payload: WhatsappTemplateSendData) => ({
  type: SEND_WHATSAPP_TEMPLATE,
  payload,
});

export const sendWhatsAppTemplateSuccess = (payload: any) => ({
  type: SEND_WHATSAPP_TEMPLATE_SUCCESS,
  payload,
});

export const templateMessageSend = (payload: boolean) => ({
  type: TEMPLATE_MESSAGE_SEND,
  payload,
});
