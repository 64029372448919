export const StatusTypes = Object.freeze({
  ACTIVE: 1,
  OFFLINE: 2,
  AWAY: 3,
});

export const statusNameList = [
  { key: 1, msgKey: "chat_app_profile_active", name: "aktiv" },
  { key: 2, msgKey: "chat_app_profile_offline", name: "offline" },
  { key: 3, msgKey: "chat_app_profile_away", name: "weg" },
];

export const StatusCode = Object.freeze({
    success: 200,
    unAuthenticated: 401,
    notVerified: 406,
    serverError: 500,
    alreadyExist: 300
});

export const enum MESAAGEPRIVACYLINKDATA {
  ISLINK = 1,
  ACCEPTED = 2,
  CANCELLED = 3,
  DEFAULT = 4
}
// export const statusNameList = Object.freeze(statusNameListArray);
