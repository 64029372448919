import {
  ADD_CONVERSATION_CHATBOT_DATA,
  GET_ACCESS_TOKEN_SUCCESS,
  INIT_CONVERSATION,
  INIT_CONVERSATION_SUCCESS,
  REMOVE_CONVERSATION_DATA,
  REMOVE_TYPING_PARTICIPANT,
  SET_AI_LOADING_SUGGESTION_DATA,
  SET_AI_SUGGESTION_DATA,
  SET_EXPAND_SUGGESTION,
  SET_SUGGETION_MESSAGE,
  SET_TYPING_PARTICIPANT,
  SET_TYPING_SMYLOR_BOT,
  SHOW_CHAT_LIST_MENU,
  SET_DENTIST_REPLY,
  SET_LAST_MESSAGE,
} from "../actions";

import IAction from "../../models/action";

const INIT_STATE = {
  loading: false,
  chats: {},
  chatSetting: {},
  activeChat: {
    userId: null,
    clientIdentity: null,
    dentistIdentity: null,
    conversation: null,
    conversationClient: null,
    conversationChatBotData: null
  },
  removeConversationData: null,
  dentistReplyPending: false,
  showChatList: false,
  suggetionMessage: '',
  aiSuggestionData: { suggestionList: [], loadingStatus: { isLoading: false, conversationId: null } },
  typingData: [],
  initLastMessage: undefined,
  botTyping: false
};

export default (state = INIT_STATE, action: IAction) => {
  switch (action.type) {
    case INIT_CONVERSATION: {
      return {
        ...state,
        loading: true,
        chatSetting: {},
        prevConversationClient: state.activeChat.conversationClient,
        activeChat: {
          userId: action.payload.conversationId,
          clientIdentity: null,
          dentistIdentity: null,
          conversation: null,
          conversationClient: null,
          conversationChatBotData: null
        },
        removeConversationData: null,
        suggetionMessage: '',
        aiSuggestionData: { suggestionList: [], loadingStatus: { isLoading: false, conversationId: null } },
        typingData: [],
        botTyping: false,
        initLastMessage: undefined,
      };
    };
    case INIT_CONVERSATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        activeChat: { userId: state.activeChat.userId, conversationChatBotData: state.activeChat.conversationChatBotData, ...action.payload },
      };
    };
    case SHOW_CHAT_LIST_MENU: {
      return {
        ...state,
        loading: false,
        showChatList: action.payload,
      };
    };
    case GET_ACCESS_TOKEN_SUCCESS: {
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.payload.chatId]: {
            accessToken: action.payload.accessToken,
            clientIdentity: action.payload.clientIdentity,
            dentistIdentity: action.payload.dentistIdentity,
          },
        },
      };
    };
    case ADD_CONVERSATION_CHATBOT_DATA: {
      return {
        ...state,
        activeChat: { ...state.activeChat, conversationChatBotData: action.payload }
      };
    };
    case REMOVE_CONVERSATION_DATA: {
      return {
        ...state,
        removeConversationData: action.payload
      }
    };
    case SET_SUGGETION_MESSAGE: {
      return {
        ...state,
        suggetionMessage: action.payload
      };
    };
    case SET_EXPAND_SUGGESTION: {
      return {
        ...state,
        chatSetting: { ...state.chatSetting, ...action.payload }
      };
    };
    case SET_AI_SUGGESTION_DATA: {
      let newSuggestionList: any[] = state.aiSuggestionData.suggestionList;

      if (state.aiSuggestionData.loadingStatus.isLoading) {
        newSuggestionList[0] = { ...action.payload, key: Math.floor(Date.now() / 1000) };
      } else {
        newSuggestionList = []
      }
      if (action.payload?.clean) {
        return {
          ...state,
          aiSuggestionData: {
            ...state.aiSuggestionData,
            suggestionList: []
          }
        }
      };
      return {
        ...state,
        aiSuggestionData: {
          ...state.aiSuggestionData,
          suggestionList: newSuggestionList.length > 0 ? [...newSuggestionList] :
            [{ ...action.payload, key: Math.floor(Date.now() / 1000) }, ...state.aiSuggestionData.suggestionList],
          loadingStatus: {}
        }
      }
    };
    case SET_AI_LOADING_SUGGESTION_DATA:
      return {
        ...state,
        aiSuggestionData: {
          ...state.aiSuggestionData,
          loadingStatus: action.payload.data,
          suggestionList: !(state.aiSuggestionData.loadingStatus.isLoading) &&
            [action.payload.generatingMsg, ...state.aiSuggestionData.suggestionList]
        }
      };
    case SET_TYPING_PARTICIPANT:
      let findParticipant = state.typingData.find(user => user === action.payload);
      return {
        ...state,
        typingData: findParticipant ? state.typingData : [...state.typingData, action.payload]
      };
    case REMOVE_TYPING_PARTICIPANT:
      let filterParticipant = state.typingData.filter(user => user !== action.payload);
      return {
        ...state,
        typingData: filterParticipant
      };
    case SET_TYPING_SMYLOR_BOT:
      if(state.activeChat.userId === action.payload.conversationId){
        return {
          ...state,
          botTyping: action.payload.isTyping
        };
      }else{
        return{
          ...state
        }
      }
    case SET_DENTIST_REPLY:
        return{
          ...state,
          dentistReplyPending : action.payload
        }
    case SET_LAST_MESSAGE:
        return{
          ...state,
          initLastMessage : action.payload
        }

    default:
      return state;
  }
};

export const getCurrConversation = (state: any) => {
  return state.conversation.prevConversationClient;
};

export const getAccessToken = (state: any, chatId: string) => {
  return state.conversation.chats[chatId];
};
