import { IUpdatedChatBotData } from "../../models/dentist";
import {
    ADD_CONVERSATION_CHATBOT_DATA,
    GET_ACCESS_TOKEN_SUCCESS,
    INIT_CONVERSATION,
    INIT_CONVERSATION_SUCCESS,
    REMOVE_CONVERSATION_DATA,
    SHOW_CHAT_LIST_MENU,
    SET_SUGGETION_MESSAGE,
    SET_EXPAND_SUGGESTION,
    SET_AI_SUGGESTION_DATA,
    SET_AI_LOADING_SUGGESTION_DATA,
    REMOVE_TYPING_PARTICIPANT,
    SET_TYPING_PARTICIPANT,
    SET_TYPING_SMYLOR_BOT,
    SET_ENABLE_SMYLOR_BOT,
    CREATE_CONVERSATION,
    CREATE_CONVERSATION_SUCCESS,
    SET_DENTIST_REPLY,
    SET_LAST_MESSAGE,
} from "../actions";

export const initConversation = (obj: { conversationId: number, culture: string }) => ({
    type: INIT_CONVERSATION,
    payload: obj,
});

export const initConversationSuccess = (conversation: any) => ({
    type: INIT_CONVERSATION_SUCCESS,
    payload: conversation,
});

export const getAccessTokenSuccess = (payload: any) => ({
    type: GET_ACCESS_TOKEN_SUCCESS,
    payload: payload,
});

export const showChatListMenu = (payload: any) => ({
    type: SHOW_CHAT_LIST_MENU,
    payload: payload,
});

export const addCoversationChatBotData = (payload: IUpdatedChatBotData) => ({
    type: ADD_CONVERSATION_CHATBOT_DATA,
    payload,
});

export const removeConversation = (payload: string) => ({
    type: REMOVE_CONVERSATION_DATA,
    payload,
});

export const setSuggetionMessage = (payload: string) => ({
    type: SET_SUGGETION_MESSAGE,
    payload,
});

export const setExpandSuggestion = (payload: any) => ({
    type: SET_EXPAND_SUGGESTION,
    payload,
});

export const setAiSuggestionData = (payload: any) => ({
    type: SET_AI_SUGGESTION_DATA,
    payload,
});

export const setAiSuggestionLoadingData = (payload: any) => ({
    type: SET_AI_LOADING_SUGGESTION_DATA,
    payload,
});

export const setTypingParticipant = (payload: string) => ({
    type: SET_TYPING_PARTICIPANT,
    payload,
});

export const removeTypingParticipant = (payload: string) => ({
    type: REMOVE_TYPING_PARTICIPANT,
    payload,
});

export const setSmylorBotTyping = (payload: { conversationId: number, isTyping: boolean}) => ({
    type: SET_TYPING_SMYLOR_BOT,
    payload,
});

export const setEnableChatBot = (payload: { conversationId: number, culture: string}) => ({
    type: SET_ENABLE_SMYLOR_BOT,
    payload,
});

export const createNewConversationAction = (payload: { culture: string, dentistId: any, email: string, name: string}) => ({
    type: CREATE_CONVERSATION,
    payload,
});

export const createNewConversationSuccess = (payload: { conversationId: number, culture: string}) => ({
    type: CREATE_CONVERSATION_SUCCESS,
    payload,
});

export const setDentistReply = (payload: boolean) => ({
    type: SET_DENTIST_REPLY,
    payload,
});

export const setConversationLastMessage = (payload: any) => ({
    type: SET_LAST_MESSAGE,
    payload,
});

