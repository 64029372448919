import { Suspense, useEffect } from "react";
import { connect } from "react-redux";

import Routes from "./routes";

import { getUserId, initUser } from "./redux/auth/actions";
import { getCurrentUser, getValue, removeValue, setValue } from "./helpers/utils";
import { loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { UserRole } from "./constants/user-role";
import { getChatHistory } from "./api/client";
import useGTM from "./hooks/useGTM";

const AppRenderer = (props: any) => {
  const tagManager = useGTM();
  useEffect(() => {
    if (props.languageSelectedList) {
      loadMessages(props.languageSelectedList.values, props.languageSelectedList.culture);
    }
  }, [JSON.stringify(props.languageSelectedList), props.selectedLanguage]);

  useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      if (user?.role === UserRole.CLIENT || user?.role === UserRole.PARTICIPANT)
        props.getUserIdAction(user?.email);
      props.initUserAction(user);
      const clientIdentity = getValue('clientIdentity')
      if (clientIdentity) {
        removeValue('clientIdentity');
        const obj: any = { clientIdentity, email: user.email }
        getChatHistory(obj);
      }
    }
    else {
      props.initUserAction(null);
    }

    if(process.env.REACT_APP_GTM_KEY){
      tagManager.getCode(process.env.REACT_APP_GTM_KEY)
    }
  }, []);

  useEffect(() => {
    const user = getCurrentUser();

    if (props.userID)
      if (user?.role === UserRole.PARTICIPANT) {
        setValue(process.env.REACT_APP_USER_INFO!, JSON.stringify({ ...user, id: props.userID }));
      } else {
        setValue(process.env.REACT_APP_USER_INFO!, JSON.stringify({ ...user, chatUserId: props.userID }));
      }
  }, [props.userID])

  return (
    <>
      {props.loading ? (
        <div />
      ) : (
        <LocalizationProvider language={props.selectedLanguage}>
          <Suspense fallback={<div className="loading" />}>
            <Routes />
          </Suspense>
        </LocalizationProvider>
      )}
    </>
  );
};

const mapStateToProps = ({ auth, coreReducer: { selectedLanguage, languageSelectedList } }: any) => {
  const { loading, currentUser, userID } = auth;

  return { loading, currentUser, selectedLanguage, languageSelectedList, userID };
};

const mapActionsToProps = {
  initUserAction: initUser,
  getUserIdAction: getUserId,
};

export default connect(mapStateToProps, mapActionsToProps)(AppRenderer);
