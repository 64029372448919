import TagManager, { TagManagerArgs } from "react-gtm-module";

export interface useGTMRetunProps {
    getCode: (code: string) => void;
    triggerEvent: (eventName: string) => void;
}
const useGTM = (): useGTMRetunProps => {
    const getCode = (code: string) => {
        if (code) {
            const tagManagerArgs: TagManagerArgs = { gtmId: code };
            try {
                TagManager.initialize(tagManagerArgs);
            } catch (error) {
                console.error("Error initializing Google Tag Manager:", error);
            }
        } else {
            console.warn("No GTM ID provided.");
        }
    };
    const triggerEvent = (eventName: string) => {
        console.log('eventName', eventName)
        if (eventName) {
            try {
                TagManager.dataLayer({
                    dataLayer: {
                        event: eventName,
                    },
                });
            } catch (error) {
                console.error("Error triggering GTM event:", error);
            }
        } else {
            console.warn("No event name provided.");
        }
    };

    return { getCode, triggerEvent };
};

export default useGTM;
